<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card class="mt-8">
                        <v-toolbar color="red darken-2 white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete enterprise
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <p class="text-overline mb-0">Enterprise name</p>
                            <p class="mb-0 pb-0">
                                {{ enterprise.name }}
                            </p>

                            <template v-if="domainList">
                            <p class="text-overline mb-0 mt-4">Domains ({{ domainList.length }})</p>
                            <p class="mb-0 pb-0">
                                <v-chip :v-for="(domain, idx) in domainList" :key="idx">{{ domain.name }}</v-chip>
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-4">Confirmation</p>

                            <p>Tap on the button below to delete this enterprise, including all domains, DNS records, and other related data.</p>

                            <v-alert type="warning" border="left" dense>
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <span class="font-weight-bold ml-2">This action is not reversible.</span>
                            </v-alert>

                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete my enterprise and all associated data" class="mt-0"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" label="I understand this action is not reversible, and that customer support will not be able to help me recover the data." class="mt-0"></v-checkbox>
                            <v-btn color="red" class="white--text" @click="deleteEnterprise" :disabled="!isDeleteEnterpriseConfirmed">Delete Enterprise</v-btn>
                        </v-card-text>
                    </v-card>

                    <v-expansion-panels class="mt-8 mb-6" v-if="isPermitServiceAdmin">
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p>Tap on the button below to delete this enterprise, including all domains, DNS records, and other related data.</p>
                                <p class="font-weight-bold">There will not be any confirmation prompts. This action is not reversible.</p>
                                <p class="mb-0 pb-0">
                                    <v-btn color="red" class="white--text" @click="deleteEnterpriseImmediately">Delete Enterprise</v-btn>
                                </p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        enterprise: null,
        domainList: null,
        error: null,
        deleteConfirmation1: false,
        // deleteConfirmation2: false,
        deleteConfirmation3: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isDeleteEnterpriseConfirmed() {
            return this.deleteConfirmation1 /* && this.deleteConfirmation2 */ && this.deleteConfirmation3;
        },
    },
    methods: {
        async loadEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).self.get();
                console.log(`enterprise/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.enterprise = response;
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEnterprise: false });
            }
        },
        async loadDomainList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).domain.search();
                if (Array.isArray(response.list)) {
                    this.domainList = response.list;
                }
            } catch (err) {
                console.error('failed to load enterprise list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
        async deleteEnterprise() {
            try {
                this.$store.commit('loading', { deleteEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).self.delete();
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted enterprise', message: this.$route.params.enterpriseId });
                    this.$router.push({ name: 'user-dashboard' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete enterprise', message: this.$route.params.enterpriseId });
                }
            } catch (err) {
                console.error('failed to delete enterprise', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete enterprise', message: this.$route.params.enterpriseId });
            } finally {
                this.$store.commit('loading', { deleteEnterprise: false });
            }
        },
        /**
         * Service admin can delete the enterprise and data immediately
         */
        async deleteEnterpriseImmediately() {
            try {
                this.$store.commit('loading', { deleteEnterpriseImmediately: true });
                const response = this.$client.main().service.deleteEnterprise({ enterpriseId: this.$route.params.enterpriseId });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted enterprise', message: this.$route.params.enterpriseId });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete enterprise', message: this.$route.params.enterpriseId });
                }
            } catch (err) {
                console.error('failed to delete enterprise', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete enterprise', message: this.$route.params.enterpriseId });
            } finally {
                this.$store.commit('loading', { deleteEnterpriseImmediately: false });
            }
        },
    },
    mounted() {
        this.loadEnterprise();
        this.loadDomainList();
    },
};
</script>
